import { Typography, Grid, Box } from "@mui/material";
import SectionHeader from "./SectionHeader";
import InsideSection from "./InsideSection";

function formatString(inputString) {
  try {
    const replacedString = inputString
      .replace(/more-growth/gi, "More growth")
      .replace(/more-fulfillment/gi, "More fulfillment");
    return replacedString;
  } catch (e) {
    return "No goal set yet";
  }
}

export default function GoalsSection(props) {
  const { gsf_level_1, gsf_level_2a, gsf_level_2b, gsf_level_0 } =
    props.userInfo;
  const whatYouNeed = formatString(gsf_level_0);

  return (
    <>
      <SectionHeader text="Goals" />
      <Grid container textAlign="left">
        <Grid
          item
          container
          lg={4}
          padding={3}
          direction="column"
          justifyContent="flex-start"
        >
          <InsideSection eyebrow="What you need">
            <Typography
              variant="body"
              color="quaternary.main"
              textTransform="capitalize"
            >
              {whatYouNeed || "Not set yet!"}
            </Typography>
          </InsideSection>
        </Grid>
        <Grid
          item
          container
          lg={4}
          padding={3}
          direction="column"
          justifyContent="flex-start"
        >
          <InsideSection eyebrow="Your overall goal">
            <Typography variant="body">
              {gsf_level_1 || "Not set yet!"}
            </Typography>
          </InsideSection>
        </Grid>
        <Grid
          item
          container
          lg={4}
          padding={3}
          direction="column"
          justifyContent="flex-start"
        >
          <InsideSection eyebrow="How you'll get there">
            <Typography variant="body" component={Box}>
              {gsf_level_2a || "Not set yet!"}
            </Typography>

            <Typography variant="body" component={Box}>
              {gsf_level_2b || "Not set yet!"}
            </Typography>
          </InsideSection>
        </Grid>
      </Grid>
    </>
  );
}
