import { useEffect, useState } from "react";
import { Grid } from "@mui/material";

import GoalsSection from "../components/GoalsSection";
import GraphsSection from "../components/GraphsSection";
import ToolsSection from "../components/ToolsSection";
import useAuthenticatedRequest from "../hooks/useAuthenticatedRequest";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import CircularProgress from "@mui/material/CircularProgress";

function Dashboard() {
  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up("lg"));

  const { sendRequest } = useAuthenticatedRequest();
  const [userInfo, setUserInfo] = useState(null);
  const [dashboardData, setDashboardData] = useState();

  useEffect(() => {
    if (!userInfo) {
      const fetchUserData = async () => {
        try {
          const userData = await sendRequest(
            `${process.env.REACT_APP_API_BASE_URL}/userInfo`,
            "GET"
          );
          setUserInfo(userData);
        } catch (err) {
          console.error("Failed to fetch data:", err.message);
        }
      };
      fetchUserData();
    }
  }, [userInfo]);

  useEffect(() => {
    if (!dashboardData) {
      const fetchDashboardData = async () => {
        try {
          const dashboardResponse = await sendRequest(
            `${process.env.REACT_APP_API_BASE_URL}/dashboard`,
            "GET"
          );
          setDashboardData(dashboardResponse);
        } catch (err) {
          console.error("Failed to fetch data:", err.message);
        }
      };
      fetchDashboardData();
    }
  }, [dashboardData]);

  return (
    <>
      {dashboardData && userInfo ? (
        <Grid container justifyContent="flex-start" alignItems="flex-start">
          <Grid
            item
            container
            lg={8}
            direction="row"
            alignItems="flex-start"
            justifyContent="flex-start"
          >
            <GoalsSection userInfo={userInfo} />
            <ToolsSection userInfo={userInfo} dashboardData={dashboardData} />
          </Grid>
          <Grid
            item
            container
            lg={4}
            direction="column"
            justifyContent="flex-start"
            alignItems="center"
            rowGap={2}
            sx={isLargeScreen ? { position: "relative", top: "-50px" } : {}}
          >
            <GraphsSection data={dashboardData} />
          </Grid>
        </Grid>
      ) : (
        <CircularProgress />
      )}
    </>
  );
}

export default Dashboard;
