import React, { useRef, useState, useEffect } from 'react';
import { Button, TextField, Grid, Typography, Paper } from '@mui/material';
import { useAuth } from '../contexts/AuthContext';
import { useNavigate } from 'react-router-dom';
import { isSignInWithEmailLink, signInWithEmailLink } from 'firebase/auth';

const LoginWithEmailLink = () => {
  const { mySendSignInLinkToEmail, currentUser, auth } = useAuth(); // Add emailSignInInitiated here
  const navigate = useNavigate();
  const emailRef = useRef();

  const [error, setError] = useState('');
  const [infoMsg, setInfoMsg] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (currentUser) {
      // user is already signed in
      navigate('/dashboard');
    } else {
      // user is not signed in but the link is valid
      if (isSignInWithEmailLink(auth, window.location.href)) {
        // now in case user clicks the email link on a different device, we will ask for email confirmation
        let email = localStorage.getItem('email');
        if (!email) {
          email = window.prompt('Please provide your email');
        }
        // after that we will complete the login process
        setLoading(true);
        signInWithEmailLink(auth, localStorage.getItem('email'), window.location.href)
          .then((result) => {
            // we can get the user from result.user but no need in this case
            console.log(result.user);
            localStorage.removeItem('email');
            setLoading(false);
            setError('');
            navigate('/');
          })
          .catch((err) => {
            setLoading(false);
            setError(err.message);
            navigate('/login');
          });
      } else {
        console.log('enter email and sign in');
      }
    }
  }, [currentUser, , navigate]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const email = emailRef.current.value;

    try {
      setError('');
      setLoading(true);
      mySendSignInLinkToEmail(email)
        .then(() => {
          localStorage.setItem('email', email);
          setLoading(false);
          setError('');
          setInfoMsg('We have sent you an email with a link to sign in');
        })
        .catch((err) => {
          setLoading(false);
          setError(err.message);
        });
    } catch (err) {
      console.log(err);
      setError('Failed to send login email');
    }

    setLoading(false);
  };

  return (
    <Paper sx={{ maxWidth: '600px', mt: 5, p: 3, pr: 10, pl: 10 }}>
      <Grid container direction="column">
        <Grid item>
          <Typography variant="h3">Login with email link</Typography>
        </Grid>
        {error && (
          <Grid item>
            <Typography variant="body1" color="error">
              {error}
            </Typography>
          </Grid>
        )}
        <form onSubmit={handleSubmit}>
          <Grid item mt={2}>
            <TextField fullWidth inputRef={emailRef} required label="Email" type="email" />
          </Grid>
          <Grid item mt={2}>
            <Button disabled={loading} type="submit" variant="contained" color="primary">
              Login
            </Button>
            {infoMsg && <Typography variant="body1">{infoMsg}</Typography>}
          </Grid>
        </form>
      </Grid>
    </Paper>
  );
};

export default LoginWithEmailLink;
