import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import { Drawer, List, ListItem, ListItemText } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";

import { useAuth } from "../contexts/AuthContext";
import AllspringWordsLogo from "../assets/logo-word-light.svg";
import HeaderSection from "./HeaderSection";
import useAuthenticatedRequest from "../hooks/useAuthenticatedRequest";

const WordsLogo = () => (
  <Box component="img" src={AllspringWordsLogo} sx={{ maxHeight: "50px" }} />
);
const LogoSection = () => (
  <Box sx={{ display: "flex", alignItems: "center" }}>
    <WordsLogo />
  </Box>
);

function DrawerComponent() {
  const { logout } = useAuth();
  const [openDrawer, setOpenDrawer] = useState(false);
  return (
    <>
      <Drawer
        anchor="right"
        open={openDrawer}
        onClose={() => setOpenDrawer(false)}
      >
        <List>
          <ListItem>
            <ListItemText>
              <Link
                to="/overview"
                style={{ textDecoration: "none", color: "black" }}
              >
                Overview
              </Link>
            </ListItemText>
          </ListItem>
          <ListItem>
            <ListItemText>
              <Link
                to="/dashboard"
                style={{ textDecoration: "none", color: "black" }}
              >
                Dashboard
              </Link>
            </ListItemText>
          </ListItem>
          <ListItem>
            <ListItemText>
              <Link
                to="https://go.tryallspring.com/askacoach"
                target="_blank"
                style={{ textDecoration: "none", color: "black" }}
              >
                Need Help?
              </Link>
            </ListItemText>
          </ListItem>
          <ListItem>
            <ListItemText>
              <Button variant="outlined" onClick={logout}>
                Logout
              </Button>
            </ListItemText>
          </ListItem>
        </List>
      </Drawer>
      <IconButton onClick={() => setOpenDrawer(!openDrawer)}>
        <MenuIcon />
      </IconButton>
    </>
  );
}

export default function AllspringAppbar() {
  const { logout, currentUser } = useAuth();
  const theme = useTheme();

  const { pathname } = useLocation();
  const { sendRequest } = useAuthenticatedRequest();
  const [userInfo, setUserInfo] = useState(null);
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  useEffect(() => {
    if (currentUser) {
      const fetchData = async () => {
        try {
          const userData = await sendRequest(
            `${process.env.REACT_APP_API_BASE_URL}/userInfo`,
            "GET"
          );
          setUserInfo(userData);
        } catch (err) {
          console.error("Failed to fetch data:", err.message);
        }
      };

      fetchData();
    }
  }, [sendRequest, currentUser]);

  const NavSection = () => (
    <Box sx={{ display: "flex", alignItems: "center", ml: 2 }}>
      <MenuItem sx={{ mr: 1, p: 1 }}>
        <Link to="/overview" style={{ textDecoration: "none", color: "black" }}>
          Overview
        </Link>
      </MenuItem>
      <MenuItem sx={{ mr: 1, p: 1 }}>
        <Link
          to="/dashboard"
          style={{ textDecoration: "none", color: "black" }}
        >
          Dashboard
        </Link>
      </MenuItem>
      <MenuItem sx={{ mr: 1, p: 1 }}>
        <Link
          to="https://go.tryallspring.com/askacoach"
          target="_blank"
          style={{ textDecoration: "none", color: "black" }}
        >
          Need Help?
        </Link>
      </MenuItem>
      {currentUser && (
        <Button variant="outlined" onClick={logout}>
          Logout
        </Button>
      )}
    </Box>
  );
  return (
    <AppBar
      position="static"
      sx={{ backgroundColor: "secondary.main", boxShadow: "none" }}
    >
      <Toolbar sx={{ display: "flex", justifyContent: "right" }}>
        <LogoSection />
        {isMobile ? <DrawerComponent /> : <NavSection />}
      </Toolbar>
      {currentUser && userInfo && pathname === "/dashboard" && (
        <HeaderSection userInfo={userInfo} />
      )}
    </AppBar>
  );
}
