import { createTheme } from '@mui/material/styles';

const colors = {
  orange: '#E17452',
  green: '#AEAE5F',
  lightGreen: '#E3E1D3',
  lightOrange: '#E6BB91',
  lightBlue: '#d1dfe8',
  lemon: '#FFF8CB',
  darkGrey: '#2E2E2E',
  darkGreen: '#bb521f',
  lightPurple: '#C9BEBC',
  lightPink: '#EEE0DC',
  beige: '#F2E2CC',
  lightGrey: '#F9F7F7',
  creme: '#FEFBF5',
};

let theme = createTheme({
  palette: {
    primary: {
      main: colors.darkGreen,
    },
    secondary: {
      main: colors.lightBlue,
    },
    tertiary: {
      main: colors.lightGrey,
    },
    quaternary: {
      main: colors.darkGrey,
    },
  },
});

theme = createTheme(theme, {
  palette: {
    info: {
      main: theme.palette.secondary.main,
    },
  },
  typography: {
    h1: {
      fontFamily: 'Feijoa Display',
    },
    h2: {
      fontFamily: 'National Medium',
    },
    h3: {
      fontFamily: 'National Medium',
    },
    h4: {
      fontFamily: 'National',
    },
    h5: {
      fontFamily: 'National',
    },
    h6: {
      fontFamily: 'National',
    },
    body1: {
      fontFamily: 'National',
    },
    body2: {
      fontFamily: 'National',
    },
  },
});

export default theme;
