import { Typography } from '@mui/material';

export default function SectionHeader(props) {
  return (
    <Typography
      variant="h2"
      color="primary"
      textTransform="uppercase"
      sx={{
        textAlign: 'left',
        fontSize: '2rem',
        fontWeight: 500,
        pt: 2,
        width: '100%',
        borderBottom: '1px solid black',
      }}
    >
      {props.text}
    </Typography>
  );
}
