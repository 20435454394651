import { Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

export default function HeaderSection(props) {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('md'));
  const fontSize = matches ? '2rem' : '3rem';

  return (
    <Typography
      variant="h1"
      sx={{
        fontSize,
        mb: 4,
        textAlign: 'left',
        backgroundColor: 'secondary.main',
        paddingLeft: 5,
        color: 'quaternary.main',
      }}
    >
      Welcome to your dashboard, {props.userInfo.firstName}
    </Typography>
  );
}
