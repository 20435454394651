import { Routes, Route, Navigate } from 'react-router-dom';
import { Container } from '@mui/material';
import './App.css';

import AllspringAppbar from './components/Appbar';
import { AuthProvider } from './contexts/AuthContext';
import PrivateRouteWrapper from './components/PrivateRouteWrapper';

import Dashboard from './pages/Dashboard';
import LoginWithEmailLink from './pages/LoginWithEmailLink';
import Overview from './pages/Overview';

function App() {
  return (
    <>
      <AuthProvider>
        <AllspringAppbar />
        <Container
          maxWidth="none"
          className="App"
          sx={{ height: '100%', textAlign: '-webkit-center', backgroundColor: 'tertiary.main' }}
        >
          <Routes>
            <Route path="/" element={<Navigate to="/login" />} />
            <Route path="/login" element={<LoginWithEmailLink />} />
            <Route path="/dashboard" element={<PrivateRouteWrapper />}>
              <Route index element={<Dashboard />} />
            </Route>
            <Route path="/overview" element={<PrivateRouteWrapper />}>
              <Route index element={<Overview />} />
            </Route>
          </Routes>
        </Container>
      </AuthProvider>
    </>
  );
}

export default App;
