import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyDy7HMnU6nnJd8x7_T1gtBLl19_6Ff_AKk",
  authDomain: "allspring-auth.firebaseapp.com",
  projectId: "allspring-auth",
  storageBucket: "allspring-auth.appspot.com",
  messagingSenderId: "547433390150",
  appId: "1:547433390150:web:5387e30c798c010a4fea47",
  measurementId: "G-PQ38NFM1FE",
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
export { auth };
