import React, { useState, useEffect } from "react";
import BarChart from "./BarChart";
import AllspringPaper from "./AllspringPaper";
import { Grid } from "@mui/material";

const weeklyOptions = {
  maintainAspectRatio: false,
  plugins: {
    title: {
      display: true,
      text: "For the last few weeks of the diagnostic, you have felt...",
    },
    legend: {
      display: true,
    },
  },
  scales: {
    y: {
      stacked: true,
      max: 100,
      min: 0,
      ticks: {
        beginAtZero: true,
      },
    },
    x: {
      stacked: true,
    },
  },
};

const overallOptions = {
  maintainAspectRatio: false,
  plugins: {
    title: {
      display: true,
      text: "Throughout your Allspring Experience you have felt...",
    },
    legend: {
      display: true,
    },
  },
  scales: {
    y: {
      stacked: false,
      ticks: {
        beginAtZero: true,
      },
    },

    x: {
      stacked: false,
    },
  },
};

const invisibleData = {
  labels: [""],
  datasets: [
    {
      label: "",
      data: [0, 100],
      backgroundColor: "rgba(0, 0, 0, 0)", // Transparent background
      borderColor: "rgba(0, 0, 0, 0)", // Transparent border
    },
  ],
};
const graphColors = {
  "I Feel Good": "#e6bb91",
  "Things Could Be Better": "#aeae5f",
  "I'm Growing": "#d1dfe8",
};

export default function GraphsSection({ data = {} }) {
  const [weeklyGraphData, setWeeklyGraphData] = useState(invisibleData);
  const [overallGraphData, setOverallGraphData] = useState(invisibleData);
  const { weeksData = [], allTimeData = {} } = data;
  useEffect(() => {
    if (!data) return;
    const weeklyDataSets = [
      {
        label: "I Feel Good",
        backgroundColor: graphColors["I Feel Good"],
        data: weeksData.map((d) => d["I Feel Good"]),
        stack: "stack1",
        borderRadius: 5,
      },
      {
        label: "Things Could Be Better",
        backgroundColor: graphColors["Things Could Be Better"],
        data: weeksData.map((d) => d["Things Could Be Better"]),
        stack: "stack1",
        borderRadius: 5,
      },
      {
        label: "I'm Growing",
        backgroundColor: graphColors["I'm Growing"],
        data: weeksData.map((d) => d["I'm Growing"]),
        stack: "stack1",
        borderRadius: 5,
      },
    ];

    const weeklyChartData = {
      labels: weeksData.map((d) => d.name),
      datasets: weeklyDataSets,
    };

    const overallDataSets = [
      {
        label: "I Feel Good",
        backgroundColor: graphColors["I Feel Good"],
        data: [allTimeData?.["I Feel Good"] || 0],
        borderRadius: 5,
      },
      {
        label: "Things Could Be Better",
        backgroundColor: graphColors["Things Could Be Better"],
        data: [allTimeData?.["Things Could Be Better"] || 0],
        borderRadius: 5,
      },
      {
        label: "I'm Growing",
        backgroundColor: graphColors["I'm Growing"],
        data: [allTimeData?.["I'm Growing"] || 0],
        borderRadius: 5,
      },
    ];
    const overallChartData = {
      labels: ["Overall"],
      datasets: overallDataSets,
    };

    setOverallGraphData(overallChartData);
    setWeeklyGraphData(weeklyChartData);
  }, [data]);

  return (
    <>
      {/* Weekly Chart */}
      <Grid item width="85%">
        <AllspringPaper>
          <BarChart chartData={weeklyGraphData} options={weeklyOptions} />
        </AllspringPaper>
      </Grid>

      {/* Overall Chart */}
      <Grid item width="85%" textAlign="center">
        <AllspringPaper>
          <BarChart chartData={overallGraphData} options={overallOptions} />
        </AllspringPaper>
      </Grid>
    </>
  );
}
