import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import SectionHeader from "./SectionHeader";

export default function ToolsSection({ userInfo, dashboardData }) {
  const { completed_topics } = userInfo;

  const [topics, setTopics] = useState(["Topic 1", "Topic 2", "Topic 3"]);

  useEffect(() => {
    if (completed_topics) {
      //have a maxiumum of 3 topics and replace the placeholders with the actual topics as they get them
      let emptyTopics = [
        {
          currentTopic: "Topic 1",
          response: {
            surprised: "No Response Yet",
            takeAway: "No Response Yet",
          },
        },
        {
          currentTopic: "Topic 2",
          response: {
            surprised: "No Response Yet",
            takeAway: "No Response Yet",
          },
        },
        {
          currentTopic: "Topic 3",
          response: {
            surprised: "No Response Yet",
            takeAway: "No Response Yet",
          },
        },
      ];

      let conclusionFlowResponses = [];
      const slackResponses = dashboardData.slackResponses ?? [];
      const conclusions = slackResponses
        .filter((response) => response.responseType === "conclusion")
        .map((response) => {
          return response;
        });
      const topics = emptyTopics.map((topic, index) => {
        return conclusions[index] ?? topic;
      });

      setTopics(topics);
    }
  }, [completed_topics]);

  return (
    <>
      <SectionHeader text="Tools you have gained" />
      <Box padding={1} width={"100%"}>
        {topics ? (
          topics.map((topic, index) => {
            return (
              <Accordion key={index} sx={{ mb: 1, textAlign: "left" }}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography variant="h3" sx={{ fontSize: "1.5rem" }}>
                    {topic.currentTopic}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography
                    variant="h4"
                    p={1}
                    sx={{
                      fontSize: "1.25rem",
                      fontWeight: "700",
                      color: "primary.main",
                    }}
                  >
                    What is something that surprised you?
                  </Typography>
                  <Typography variant="body1" pl={2}>
                    {topic.response?.surprised
                      ? topic.response.surprised
                      : "No Response Yet"}
                  </Typography>
                  <Typography
                    variant="h4"
                    p={1}
                    sx={{
                      fontSize: "1.25rem",
                      fontWeight: "700",
                      color: "primary.main",
                    }}
                  >
                    What is something that you'll take away from this topic?
                  </Typography>
                  <Typography variant="body1" pl={2}>
                    {topic.response?.takeAway
                      ? topic.response.takeAway
                      : "No Response Yet"}
                  </Typography>
                </AccordionDetails>
              </Accordion>
            );
          })
        ) : (
          <Typography variant="body1">No tools yet</Typography>
        )}
      </Box>
    </>
  );
}
