import { Typography } from "@mui/material";
import AllspringPaper from "./AllspringPaper";

export default function InsideSection({ children, eyebrow, ...props }) {
  return (
    <>
      {eyebrow && (
        <Typography
          variant="h3"
          sx={{
            fontWeight: 700,
            fontSize: "1.5rem",
            color: "quaternary.main",
            paddingBottom: 2,
          }}
        >
          {eyebrow}
        </Typography>
      )}
      <AllspringPaper {...props}>{children}</AllspringPaper>
    </>
  );
}
